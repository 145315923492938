/* Redeem dialog */
[data-redeem-dialog-content] {
  @apply fixed left-1/2 top-1/2 z-[100] w-full max-w-[95%] -translate-x-1/2 -translate-y-1/2 rounded-md bg-white text-[#212732] shadow-xl sm:max-w-md;
  [data-title] {
    @apply px-8 pt-8 text-2xl font-bold;
  }
  [data-description] {
    @apply border-b border-[#e2e7ed] px-8 pb-8 pt-4;
  }
  form {
    @apply px-8 py-4;
    [data-email] {
      @apply mt-2 flex flex-col;
      label {
        @apply pb-1;
      }
      input {
        @apply rounded-sm border border-[#e2e7ed] bg-transparent px-4 py-2 focus-visible:border-transparent;
      }
    }
    [data-actions] {
      @apply flex w-full justify-end gap-3 pb-4 pt-8;
      [data-cancel] {
        @apply ml-3 flex min-w-[80px] shrink-0 items-center justify-center gap-1 rounded-md bg-[#a0aec0] px-5 py-2 text-lg font-semibold text-white transition hover:bg-[#718096];
      }
      [data-submit] {
        @apply ml-3 flex min-w-[80px] shrink-0 items-center justify-center gap-1 rounded-md bg-indigo-600 px-5 py-2 text-lg font-semibold text-white transition hover:bg-indigo-700;
      }
      [data-submit]:hover {
        @apply brightness-105;
      }
    }
  }
}
[data-redeem-dialog-overlay] {
  @apply fixed inset-0 z-50 bg-slate-950/30 backdrop-blur-sm;
}
