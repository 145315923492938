#newsletter {
  #primary-newsletter-cta {
    [data-sr-convertkit-subscribe-form] {
      @apply mx-auto flex w-full max-w-none flex-col items-center justify-center gap-3 px-3 sm:max-w-[360px];
    }
    [data-sr-fieldset] {
      @apply w-full sm:max-w-[360px];
    }
    [data-sr-input] {
      @apply h-12 border border-gray-200 bg-gray-100 shadow-inner dark:border-gray-800 dark:bg-black/30;
    }
    [data-sr-button] {
      @apply mt-3 h-14 w-full;
    }
  }
}

#primary-newsletter-cta {
  @apply w-full;
  [data-sr-convertkit-subscribe-form] {
    @apply mx-auto flex w-full max-w-screen-lg flex-col items-center justify-center gap-3 px-3 md:flex-row md:items-end;
    [data-sr-input] {
      @apply w-full px-4 py-3;
    }
    [data-sr-fieldset] {
      @apply w-full max-w-none sm:max-w-[250px];
    }
    [data-sr-input-label] {
      @apply pb-2;
    }
    [data-sr-button] {
      @apply w-full bg-blue-500 font-semibold sm:w-auto;
      svg {
        @apply h-5 w-5;
      }
    }

    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
  }
  [data-sr-convertkit-subscribe-form='error'] {
  }
}

#video-overlay {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full max-w-sm flex-col gap-4 text-left;
    [data-sr-input] {
      @apply w-full rounded-md border bg-gray-600 px-3 py-3 text-base;
    }
    [data-sr-input-label] {
      @apply inline-block w-full pb-1 text-base text-gray-300;
    }
    [data-sr-button] {
      @apply relative mt-2 flex items-center justify-center rounded-md bg-blue-500 px-5 py-7 text-lg font-semibold text-white shadow-2xl  transition hover:bg-blue-400;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-nospam] {
    @apply text-sm text-white;
  }
}

#subscribe-embed {
  [data-sr-convertkit-subscribe-form] {
    @apply flex  w-full max-w-sm flex-col gap-2 text-left;
    [data-sr-input] {
      @apply h-8 w-full rounded-md border border-gray-300 bg-gray-100 text-xs dark:border-gray-700 dark:bg-gray-800 dark:placeholder-white/80 sm:h-11 sm:text-base;
    }
    [data-sr-input-label] {
      @apply hidden w-full text-xs dark:text-gray-300 sm:text-base;
    }
    [data-sr-button] {
      @apply relative mt-2 flex h-8 items-center justify-center rounded-md bg-blue-500 text-sm font-semibold text-white shadow-2xl transition hover:bg-blue-600 sm:h-11 sm:text-base;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-nospam] {
    @apply text-sm text-white;
  }
}

#tip {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full flex-col gap-2 text-left lg:flex-row;
    [data-sr-input-wrapper] {
      @apply w-full;
    }
    [data-sr-input-wrapper]:first-of-type {
      @apply lg:w-2/3;
    }
    [data-sr-input] {
      @apply w-full rounded-md border border-gray-300/75 bg-gray-100 px-3 py-2 text-base shadow-inner dark:border-gray-800 dark:bg-gray-800/50;
    }

    [data-sr-input-label] {
      @apply sr-only;
    }
    [data-sr-button] {
      @apply relative flex flex-shrink-0 items-center justify-center rounded-md border border-blue-700/30 bg-gradient-to-b from-blue-500 to-blue-600 px-5 py-2 text-base font-semibold text-white transition hover:brightness-110 focus-visible:ring-white;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
}
