[data-login-template] {
  @apply rounded-lg border border-er-gray-200 bg-background p-8 shadow-xl sm:mx-auto sm:w-full sm:max-w-md;
  [data-title] {
    @apply mt-6 text-center text-3xl font-semibold leading-9 text-er-gray-900;
  }
  [data-form] {
    @apply mt-8 px-4 pb-8 sm:mx-auto sm:w-full sm:max-w-md sm:px-8;
    [data-label] {
      @apply block text-sm font-medium leading-5 text-er-gray-800;
    }
    [data-input-container] {
      @apply relative mt-1 rounded-md shadow-sm;
      [data-icon] {
        @apply hidden;
      }
      [data-input] {
        @apply block w-full appearance-none rounded-md border-2 border-er-gray-200 bg-er-gray-100 px-4 py-2 leading-normal focus:shadow-outline focus:outline-none;
      }
    }
    [data-button] {
      @apply mt-6 w-full rounded-lg bg-blue-500 px-5 py-3 font-semibold text-white shadow-sm transition duration-150 ease-in-out hover:bg-blue-700;
    }
  }
  [data-separator] {
    @apply py-3 text-center text-sm opacity-60;
  }
  [data-providers-container] {
    @apply w-full space-y-2;
    [data-button] {
      @apply w-full gap-2;
    }
  }
}

[data-check-your-email] {
  @apply absolute inset-0 mx-auto flex w-full max-w-full flex-col justify-center p-5;
  [data-container] {
    @apply rounded-lg border border-er-gray-200 bg-background p-8 shadow-xl sm:mx-auto sm:w-full sm:max-w-md;
  }
  [data-title] {
    @apply mt-6 text-center text-3xl font-semibold leading-9 text-er-gray-900;
  }
  [data-message] {
    @apply mt-8 px-4 pb-8 sm:mx-auto sm:w-full sm:max-w-md sm:px-8;
  }
}
