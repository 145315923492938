[data-video-transcript] {
  @apply mx-auto max-w-4xl p-5 py-16;
  [data-title] {
    @apply flex items-baseline text-2xl font-semibold sm:text-3xl;
  }
  [data-transcript] {
    @apply prose max-w-none;
    [data-timestamp] {
      @apply inline-block underline after:inline-block after:content-['_'];
    }
  }
}

#tip {
  [data-video-transcript] {
    @apply p-0;
  }
}
