[data-invite-team] {
  @apply flex flex-col rounded-lg border border-er-gray-200 bg-background p-5 dark:border-er-gray-200;
  [data-title] {
    @apply py-3;
    /* 3 seats left */
    strong {
      @apply font-semibold;
    }
  }
  [data-copy-invite-link-container] {
    @apply w-full;
    [data-copy-invite-link] {
      /* optional */
    }
  }
  [data-redeem] {
    @apply mt-5 flex flex-col items-center gap-3 border-t border-er-gray-100 pt-5 dark:border-er-gray-200 sm:mt-8 sm:flex-row sm:justify-end;
    [data-title] {
      /* Or get access yourself */
      @apply flex items-center gap-1 font-normal;
    }
    [data-self-redeem-button] {
      @apply inline-flex transform cursor-pointer items-center justify-center rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold leading-8 text-white duration-100 hover:bg-blue-600;
    }
  }
}

/* Copy invite link form */
[data-copy-invite-link] {
  label {
    /* Invite share link */
    @apply sr-only;
  }
  div {
    @apply flex gap-3 pt-2;
  }
  input {
    @apply w-full rounded-md border border-er-gray-200 bg-er-gray-100 px-3 py-2 text-sm font-semibold selection:bg-indigo-500 selection:text-white dark:bg-background dark:text-white;
  }
  input[disabled] {
    @apply opacity-50;
  }
  [data-sr-button] {
    @apply inline-flex transform cursor-pointer items-center justify-center whitespace-nowrap rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold leading-8 text-white duration-100 hover:bg-blue-600;
    /* @apply flex flex-shrink-0 items-center gap-1 rounded-md bg-indigo-400/20 px-5 py-2 text-sm font-semibold text-indigo-500 transition hover:bg-indigo-400/30 dark:text-indigo-300; */
  }
  [data-sr-button][disabled] {
    @apply cursor-not-allowed opacity-30;
  }
}

/* Team card */
[data-team-card],
[data-product-buy-more-seats] {
  @apply flex flex-col gap-4;
  & > [data-title] {
    @apply flex gap-2;
    h1,
    h2 {
      @apply text-lg font-semibold leading-tight sm:text-xl;
    }
  }
  [data-seats-form] {
    @apply flex items-center gap-1;

    label {
      @apply mr-3 opacity-80;
    }

    input {
      @apply max-w-[70px] rounded-md border border-yellow-400 bg-transparent py-2 pl-3 font-mono font-bold;
    }
    button {
      @apply flex h-full items-center justify-center rounded bg-gray-700/50 px-3 py-2 font-mono sm:hidden;
    }
  }
  [data-buy-more-seats-form] {
    fieldset {
      @apply flex flex-col items-center rounded-lg border border-er-gray-200 p-5 dark:border-er-gray-200;
    }
    button[type='submit'] {
      @apply mt-12 block w-full transform rounded-lg border border-transparent bg-yellow-400 px-6 py-5 text-center text-lg font-semibold leading-none text-white transition-all duration-150 ease-in-out hover:scale-105 focus:border-yellow-600 focus:outline-none sm:text-xl sm:leading-none md:max-w-sm;
    }
  }
  [data-pricing-product] {
    @apply mt-8 w-full;
  }
  [data-pricing-product-header] {
    @apply flex flex-col items-center p-0;
  }
}
