h1[data-lesson-title] {
  @apply pb-5 pt-3 text-2xl font-bold sm:text-3xl xl:text-4xl;
}

[data-lesson-badge] {
  @apply inline-block rounded-full px-2.5 py-1 font-mono text-xs font-semibold uppercase sm:mt-5 lg:text-sm 2xl:mt-0 2xl:text-xs;
}
[data-lesson-badge='solution'] {
  @apply bg-blue-500/20 text-blue-500 dark:text-blue-300;
}
[data-lesson-badge='exercise'] {
  @apply bg-orange-500/20 text-orange-500 dark:text-orange-300;
}
[data-lesson-badge='explainer'] {
  @apply bg-blue-400/10 text-blue-500 dark:text-blue-400;
}
[data-lesson-badge='interview'] {
  @apply bg-sky-500/10 text-blue-500 dark:text-blue-400;
}
[data-lesson-badge='lesson'] {
  @apply bg-blue-400/10 text-blue-500 dark:text-blue-400;
}
